import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  collection,
  query,
  startAfter,
  where,
  limit,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { db, storage } from "./firebase"; // Import your Firebase database instance

const addBusinessToFirestore = async (businessData, setLoader, isUpadte) => {
  setLoader(true);
  try {
    const directoryCollection = collection(db, "directory");

    if (isUpadte) {
      // If id is present, try to update an existing document
      const existingDocRef = doc(directoryCollection, businessData.docId);
      const existingDocSnapshot = await getDoc(existingDocRef);

      if (existingDocSnapshot.exists()) {
        // If the document exists, update it
        await setDoc(existingDocRef, businessData);
        setLoader(false);
        return businessData;
      } else {
        setLoader(false);
        return null;
      }
    } else {
      // If id is not present, create a new document with the specified ID
      const customDocRef = doc(directoryCollection, businessData.docId);
      await setDoc(customDocRef, businessData);
      setLoader(false);
      return businessData;
    }

    // {
    // mail: eventData.mail,
    // nameOfBrand: eventData.nameOfBrand,
    // googleMapLink: eventData.googleMapLink,
    // phoneNumber: eventData.phoneNumber,
    // webLink: eventData.webLink,
    // picture: eventData.picture,
    // date: eventData.date,
    // fb: eventData.fb,
    // category: eventData.category,
    // insta: eventData.insta,
    // content: eventData.content,
    // docId: "", // Initialize to an empty string (will be replaced with the actual ID)
    // }
  } catch (error) {
    console.log(error);
    setLoader(false);
    return null;
  }
};

const addEventToFirestore = async (eventData, setLoader, isUpadte) => {
  setLoader(true);
  try {
    const eventsCollection = collection(db, "events");

    if (isUpadte) {
      // If id is present, try to update an existing document
      const existingDocRef = doc(eventsCollection, eventData.docId);
      const existingDocSnapshot = await getDoc(existingDocRef);

      if (existingDocSnapshot.exists()) {
        // If the document exists, update it
        await setDoc(existingDocRef, eventData);
        setLoader(false);
        return eventData;
      } else {
        setLoader(false);
        return null;
      }
    } else {
      // If id is not present, create a new document with the specified ID
      const customDocRef = doc(eventsCollection, eventData.docId);
      await setDoc(customDocRef, eventData);
      setLoader(false);
      return eventData;
    }

    // {
    // mail: eventData.mail,
    // nameOfBrand: eventData.nameOfBrand,
    // googleMapLink: eventData.googleMapLink,
    // phoneNumber: eventData.phoneNumber,
    // webLink: eventData.webLink,
    // picture: eventData.picture,
    // date: eventData.date,
    // content: eventData.content,
    // docId: "", // Initialize to an empty string (will be replaced with the actual ID)
    // }
  } catch (error) {
    console.log(error);
    setLoader(false);
    return null;
  }
};

// Function to delete document and associated image
const deleteBusinessAndImage = async (docId, imageName) => {
  try {
    // Delete document from Firestore
    const directoryDocRef = doc(db, "directory", docId);
    await deleteDoc(directoryDocRef);
    return "deleted";
  } catch (error) {
    return null;
  }
};

// Function to delete document and associated image
const deleteEventAndImage = async (docId, imageName) => {
  try {
    // Delete document from Firestore
    const eventDocRef = doc(db, "events", docId);
    await deleteDoc(eventDocRef);
    return "deleted";
  } catch (error) {
    console.log("eewrfgsf", error);
    return null;
  }
};

const getDirectoryById = async (businessId) => {
  try {
    const businessDocRef = doc(db, "directory", businessId);
    const businessDocSnapshot = await getDoc(businessDocRef);

    if (businessDocSnapshot.exists()) {
      // Document exists, return its data

      return { ...businessDocSnapshot.data() };
    } else {
      // Document does not exist
      return null;
    }
  } catch (error) {
    console.error("Error fetching business from Firestore: ", error.message);
    return null;
  }
};

const getDirectoryByCategoryASearch = async (
  businessCategory,
  searchTerm,
  lastDoc,
  pageSize
) => {
  try {
    const directoryCollection = collection(db, "directory");
    let docQuery = query(
      directoryCollection,
      where("nameOfBrand", ">=", searchTerm.toLowerCase()),
      where("nameOfBrand", "<=", `${searchTerm.toLowerCase()}\uf8ff`),
      where("category", "==", businessCategory.toUpperCase()),
      orderBy("nameOfBrand", "asc"),
      orderBy("category"),
      startAfter(lastDoc),
      limit(pageSize)
    );

    if (businessCategory === "ALL") {
      docQuery = query(
        directoryCollection,
        where("nameOfBrand", ">=", searchTerm.toLowerCase()),
        where("nameOfBrand", "<=", `${searchTerm.toLowerCase()}\uf8ff`),
        orderBy("nameOfBrand", "asc"),
        startAfter(lastDoc),
        limit(pageSize)
      );
    }

    const querySnapshot = await getDocs(docQuery);
    const directoryData = [];
    querySnapshot.forEach((doc) => {
      directoryData.push({ ...doc.data(), id: doc.id });
    });
    return {
      data: directoryData,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Store the last document for the next page
    };
  } catch (error) {
    console.error("Error fetching business from Firestore: ", error.message);
    return null;
  }
};

const getDirectoryByCategory = async (businessCategory, lastDoc, pageSize) => {
  try {
    const directoryCollection = collection(db, "directory");
    let docQuery = query(
      directoryCollection,
      where("category", "==", businessCategory.toUpperCase()),
      orderBy("category"),
      orderBy("nameOfBrand"),
      startAfter(lastDoc),
      limit(pageSize)
    );

    if (businessCategory === "ALL") {
      docQuery = query(
        directoryCollection,
        orderBy("date"),
        orderBy("nameOfBrand"),
        startAfter(lastDoc),
        limit(pageSize)
      );
    }
    const querySnapshot = await getDocs(docQuery);
    const directoryData = [];
    querySnapshot.forEach((doc) => {
      directoryData.push({ ...doc.data(), id: doc.id });
    });
    return {
      data: directoryData,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Store the last document for the next page
    };
  } catch (error) {
    console.error("Error fetching business from Firestore: ", error.message);
    return null;
  }
};

const getEventById = async (eventId) => {
  try {
    const eventDocRef = doc(db, "events", eventId);
    const eventDocSnapshot = await getDoc(eventDocRef);

    if (eventDocSnapshot.exists()) {
      // Document exists, return its data
      return { ...eventDocSnapshot.data() };
    } else {
      // Document does not exist
      return null;
    }
  } catch (error) {
    console.error("Error fetching event from Firestore: ", error.message);
    return null;
  }
};

const getEventsBySearch = async (searchTerm, pageSize, lastDoc) => {
  try {
    const eventsCollection = collection(db, "events");
    let docQuery = query(
      eventsCollection,
      where("nameOfBrand", ">=", searchTerm.toLowerCase()),
      where("nameOfBrand", "<=", `${searchTerm.toLowerCase()}\uf8ff`),
      orderBy("nameOfBrand"),
      startAfter(lastDoc),
      limit(pageSize)
    );

    const querySnapshot = await getDocs(docQuery);
    const eventsData = [];

    querySnapshot.forEach((doc) => {
      eventsData.push({ ...doc.data(), id: doc.id });
    });

    return {
      data: eventsData,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Store the last document for the next page
    };
  } catch (error) {
    console.error("Error fetching events by search term: ", error.message);
    return null;
  }
};

const getAllEventsFromFirestore = async (pageSize, lastDoc) => {
  try {
    const eventsCollection = collection(db, "events");
    let docQuery = query(
      eventsCollection,
      orderBy("date"),
      startAfter(lastDoc),
      limit(pageSize)
    );
    const querySnapshot = await getDocs(docQuery);
    const eventsData = [];
    querySnapshot.forEach((doc) => {
      eventsData.push({ ...doc.data(), id: doc.id });
    });
    return {
      data: eventsData,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Store the last document for the next page
    };
  } catch (error) {
    console.error("Error fetching events from Firestore: ", error.message);
    return null;
  }
};

const uploadImageToStorage = async (file, fileName) => {
  const storageRef = ref(storage, fileName);

  try {
    // Upload the file to Firebase Storage
    await uploadBytes(storageRef, file);

    // Get the download URL for the uploaded file
    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error("Error uploading image to Firebase Storage:", error.message);
    return null;
  }
};

const fetchProfileDataFromFirestore = async (userId) => {
  const userDocRef = doc(db, "profile", userId);

  try {
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      // If the document exists, return the data
      const userData = docSnapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error, "Fdgsbhfrt");
    return null;
  }
};

export const fetchAllImagesFromStorage = async () => {
  const storageRef = ref(storage, "blog_images");

  try {
    // List all items in the "blog_images" folder
    const { items } = await listAll(storageRef);

    // Fetch download URLs for all items
    const downloadURLs = await Promise.all(
      items.map(async (itemRef) => {
        const downloadURL = await getDownloadURL(itemRef);
        return downloadURL;
      })
    );

    console.log("Fetched all image URLs successfully:", downloadURLs);
    return downloadURLs;
  } catch (error) {
    console.error(
      "Error fetching images from Firebase Storage:",
      error.message
    );
    return [];
  }
};

const updateProfileInFirestore = async (userId, data, setLoader) => {
  const userDocRef = doc(db, "profile", userId);
  try {
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      // If the document exists, update it
      await updateDoc(userDocRef, data);
    } else {
      // If the document doesn't exist, create a new one
      await setDoc(userDocRef, data);
    }
    return "Profile Saved";
  } catch (error) {
    return null;
  } finally {
    setLoader(false);
  }
};

const updateSliderInFirestore = async (data) => {
  const slideDocRef = doc(db, "slider", "4RQzahI5e3gBwmRhYEYk");

  try {
    const docSnapshot = await getDoc(slideDocRef);

    if (docSnapshot.exists()) {
      // If the document exists, update it
      await updateDoc(slideDocRef, data);
    } else {
      // If the document doesn't exist, create a new one
      await setDoc(slideDocRef, data);
    }
    return "Image Saved";
  } catch (error) {
    return null;
  }
};

const getSliderById = async () => {
  try {
    const slideDocRef = doc(db, "slider", "4RQzahI5e3gBwmRhYEYk");
    const slideDocSnapshot = await getDoc(slideDocRef);

    if (slideDocSnapshot.exists()) {
      // Document exists, return its data
      return { ...slideDocSnapshot.data() };
    } else {
      // Document does not exist
      return null;
    }
  } catch (error) {
    console.error("Error fetching slides from Firestore: ", error.message);
    return null;
  }
};

const updateOrCreateSEO = async (id, title, description, metaTag) => {
  try {
    const seoDocRef = doc(db, "SEOFORLINKS", id);
    const seoDocSnapshot = await getDoc(seoDocRef);

    if (seoDocSnapshot.exists()) {
      // If the document exists, update it
      await updateDoc(seoDocRef, {
        title,
        description,
        metaTag,
      });
    } else {
      // If the document doesn't exist, create a new one with the specified ID
      await setDoc(seoDocRef, {
        title,
        description,
        metaTag,
      });
    }
    return "SEO update successfully";
  } catch (error) {
    console.log(error);
    return null;
  }
};

async function getSEOById(id) {
  try {
    const seoDocRef = doc(collection(db, "SEOFORLINKS"), id);
    const seoDocSnapshot = await getDoc(seoDocRef);

    if (seoDocSnapshot.exists()) {
      const seoData = seoDocSnapshot.data();
      return seoData;
    } else {
      return "no-doc";
    }
  } catch (error) {
    console.error("Error getting SEO document:", error);
    return null;
  }
}

export {
  updateProfileInFirestore,
  addEventToFirestore,
  updateOrCreateSEO,
  getAllEventsFromFirestore,
  getSliderById,
  getSEOById,
  uploadImageToStorage,
  getEventById,
  getEventsBySearch,
  deleteBusinessAndImage,
  addBusinessToFirestore,
  getDirectoryByCategoryASearch,
  updateSliderInFirestore,
  getDirectoryById,
  deleteEventAndImage,
  getDirectoryByCategory,
  fetchProfileDataFromFirestore,
};
