import React from "react";
import "./whyus.css";
import ecoGift from "../../assets/images/ecoGifts.jpg";
import sustain from "../../assets/images/sustain-tourism.jpg";
import visit from "../../assets/images/visit.jpg";

const WhyUs = () => {
  const data = [
    { image: visit },
    {
      image: ecoGift,
    },
    {
      image: sustain,
    },
  ];

  return (
    <div className="whyUs">
      <h1>Why are you here?</h1>
      <div className="imageContainer">
        {data.map((itm, ind) => (
          <div className="imageDiv" key={ind}>
            <img alt="whyhere" src={itm.image} />
            <h1>
              {ind === 0
                ? "You are..."
                : ind === 1
                ? "...looking for..."
                : "...because"}
            </h1>
            <ul>
              {ind === 0 ? (
                <>
                  <li>Living in Pondicherry.</li>
                  <li>Visiting Pondicherry.</li>
                  <li>Working in Pondicherry.</li>
                </>
              ) : ind === 1 ? (
                <>
                  <li>Ecofriendly and local gifts.</li>
                  <li>Organic groceries.</li>
                  <li>Recycling center.</li>
                  <li>Sustainable tourism and activities.</li>
                  <li>And many more...</li>
                </>
              ) : (
                <>
                  <li>
                    You don't know what is available just next to you so you
                    prefer ordering online through huge platform.
                  </li>
                  <li>
                    Your way of buying could have a positive impact by being
                    ecofriendly.
                  </li>
                </>
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
