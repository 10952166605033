import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const SearchBox = ({ classes }) => {
  const navigate = useNavigate();
  const [category, setcategory] = useState("ALL");
  const [searchInput, setsearchInput] = useState("");
  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();

  const handleSearchCategory = () => {
    if (searchInput.length === 0) {
      navigate(`/directory/${category}/all`);
    } else {
      navigate(`/directory/${category}/${searchInput}`);
    }
    // Track the search event with dynamic values
    trackEvent(
      category,
      "search_brand_at_hero",
      searchInput ? searchInput : "all"
    );
  };

  return (
    <div className={`SearchBox ${classes}`}>
      <ul className="filterTab">
        <li
          style={{
            backgroundColor: "white",
            color: "#222222",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
          Looking for a Brand ?
        </li>
      </ul>
      <div className="searchWrapper">
        <div className="searchInputBox">
          <input
            value={searchInput}
            onChange={(e) => setsearchInput(e.target.value)}
            placeholder="Type your keyword here"
          />
          <button onClick={handleSearchCategory}>Ok</button>
        </div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          fullWidth
          label="Category"
          onChange={(e) => setcategory(e.target.value)}
        >
          <MenuItem value={"ALL"}>ALL</MenuItem>
          <MenuItem value={"ARTS AND CRAFTS"}>ARTS AND CRAFTS</MenuItem>
          <MenuItem value="ECOTOURISM">ECOTOURISM</MenuItem>
          <MenuItem value={"ORGANIC AND LOCAL FOOD"}>
            ORGANIC AND LOCAL FOOD
          </MenuItem>
          <MenuItem value="RECYCLING AND SECOND HAND">
            RECYCLING AND SECOND HAND
          </MenuItem>
          <MenuItem value={"WELL BEING AND LIFESTYLE"}>
            WELL BEING AND LIFESTYLE
          </MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default SearchBox;
