import React, { useEffect, useState } from "react";
import "./event.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate } from "react-router-dom";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
// import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import * as noData from "../../assets/images/noData.json";
import Carousel from "react-multi-carousel";
import Lottie from "react-lottie";
import {
  getAllEventsFromFirestore,
  getEventsBySearch,
  getEventById,
  getSEOById,
} from "../../firebase/functions";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 3,
  },
  smallLaptop: {
    breakpoint: { max: 1250, min: 952 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 925, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: noData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const PAGE_SIZE = 25;
const Event = () => {
  const { title, name } = useParams();
  const navigate = useNavigate();
  const [showImageBig, setshowImageBig] = useState(false);
  const [rows, setrows] = useState([]);
  const [loaderForMore, setloaderForMore] = useState(false);
  const [searchEvent, setsearchEvent] = useState("");
  const [loaderForSearch, setloaderForSearch] = useState(false);
  const [fetchLoader, setfetchLoader] = useState(true);
  const [lastDocument, setLastDocument] = useState(null);
  const [singleEvent, setsingleEvent] = useState(null);
  const [seoContent, setseoContent] = useState({
    title: "Green Links",
    description: "",
    metaTag: "",
  });

  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();

  useEffect(() => {
    (async () => {
      const seo = await getSEOById({ id: "events" });
      if (seo === null) {
        setseoContent({
          title: "Green Links",
          description: "",
          metaTag: "",
        });
      } else {
        setseoContent(seo);
      }
    })();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  useEffect(() => {
    if (!name || !title) {
      navigate("/");
    }
  }, [name, title]);

  useEffect(() => {
    // Define a timer variable to store the setTimeout ID
    let timer;

    // Function to fetch events
    const fetchEventsBySearch = async () => {
      setloaderForSearch(true);
      const result = await getEventsBySearch(searchEvent, PAGE_SIZE, null);
      setloaderForSearch(false);
      if (result) {
        setrows(result.data);
        if (result.lastDoc === undefined) {
          setLastDocument(null);
        } else {
          setLastDocument(result.lastDoc);
        }
      } else {
        toast.error("Error occured, try again later.");
      }
    };

    // Debounce the fetch function
    const debouncedFetch = () => {
      // Clear the existing timer if it exists
      if (timer) {
        clearTimeout(timer);
      }

      // Set a new timer to call the fetch function after 1000 milliseconds
      timer = setTimeout(fetchEventsBySearch, 1000); // Adjust the delay as needed
    };

    const fetchEventById = async (eventId) => {
      const eventData = await getEventById(eventId);
      if (eventData !== null) {
        setsingleEvent(eventData);
      } else {
        toast.dismiss();
        toast.error("No Event exists");
        navigate("/error");
      }
      setfetchLoader(false);
    };

    const fetchEventData = async () => {
      const eventData = await getAllEventsFromFirestore(PAGE_SIZE, null);
      if (eventData !== null) {
        setrows(eventData.data);
        if (eventData.lastDoc === undefined) {
          setLastDocument(null);
        } else {
          setLastDocument(eventData.lastDoc);
        }
      }
      setfetchLoader(false);
    };

    if (title !== undefined) {
      if (title === "all") {
        if (searchEvent.length === 0) {
          fetchEventData();
        } else {
          debouncedFetch();
        }
      } else {
        fetchEventData();
        fetchEventById(title);
      }
    } else {
      navigate("/");
    }
    scrollToTop();
  }, [title, searchEvent]);

  const fetchMoreData = async (searchEvent) => {
    if (lastDocument === null) return;
    setloaderForMore(true);
    let eventData = null;
    if (searchEvent.length === 0) {
      eventData = await getAllEventsFromFirestore(PAGE_SIZE, lastDocument);
    } else {
      eventData = await getEventsBySearch(searchEvent, PAGE_SIZE, lastDocument);
    }
    if (eventData !== null) {
      setrows((prevRows) => [...prevRows, ...eventData.data]);
      if (eventData.lastDoc === undefined) {
        setLastDocument(null);
      } else {
        setLastDocument(eventData.lastDoc);
      }
    } else {
      toast.error("Error occured, try again");
    }
    setloaderForMore(false);
  };

  return (
    <div className="event">
      <Helmet>
        <title>{seoContent.title}</title>
        <meta name="description" content={seoContent.description} />
        <meta name="keywords" content={seoContent.metaTag} />
      </Helmet>
      {fetchLoader ? (
        <div className="scrollWrapper">
          <CircularProgress sx={{ margin: "auto" }} />
        </div>
      ) : title === "all" ? (
        <>
          <h1>Latest News & Events</h1>
          <input
            value={searchEvent}
            onChange={(e) => setsearchEvent(e.target.value)}
            placeholder="Search Events By Name"
            className="eventInputField"
          />
          {loaderForSearch ? (
            <div className="scrollWrapper">
              <CircularProgress sx={{ margin: "auto" }} />
            </div>
          ) : rows.length === 0 ? (
            <div className="noDataImage">
              <Lottie options={defaultOptions} height={400} width={400} />
            </div>
          ) : (
            // <img src={noData} alt="noData" className="noDataImage" />
            <div className="eventDataRender">
              {rows.map((eventItem, ind) => {
                return (
                  <div key={ind} className="card">
                    <img
                      className="bgImage"
                      src={eventItem.picture}
                      alt="links"
                    />

                    <div className="content">
                      <h1>{eventItem.nameOfBrand}</h1>
                      {/* <p>
                        {(eventItem?.content ?? []).length > 0
                          ? (eventItem?.content ?? [])[0].value.length > 20
                            ? (eventItem?.content ?? [])[0].value.substring(
                                0,
                                20
                              )
                            : (eventItem?.content ?? [])[0].value
                          : ""}
                      </p> */}
                    </div>
                    <button
                      onClick={() =>
                        navigate(
                          `/events/${eventItem.nameOfBrand}/${eventItem.docId}`
                        )
                      }
                    >
                      Visit Here
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {rows.length > 0 && lastDocument !== null && (
            <button
              className="fetchMoreButton"
              onClick={() => fetchMoreData(searchEvent)}
            >
              {loaderForMore ? (
                <CircularProgress sx={{ margin: "auto" }} />
              ) : (
                "Fetch More"
              )}
            </button>
          )}
        </>
      ) : (
        <div className="content">
          <Dialog open={showImageBig} onClose={() => setshowImageBig(false)}>
            <img alt="fs" src={singleEvent?.picture} />
          </Dialog>
          <img
            alt="fs"
            src={singleEvent?.picture}
            onClick={() => setshowImageBig(true)}
          />

          <div className="header">
            <h5>23 December, 2023</h5>
            <div className="share">
              {singleEvent?.webLink && (
                <a
                  onClick={() => {
                    trackEvent(
                      singleEvent?.nameOfBrand,
                      "clicked_on_web_link",
                      singleEvent?.docId
                    );
                  }}
                  href={singleEvent?.webLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Web Link <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              )}
            </div>
          </div>
          <h1>{singleEvent?.nameOfBrand}</h1>

          <div id="contentitis">
            <div
              dangerouslySetInnerHTML={{
                __html: singleEvent?.content,
              }}
            />
          </div>

          {/* <div style={{ wordWrap: "break-word" }}>
            {(singleEvent?.content ?? []).map((cnt, ind) =>
              cnt.type === "h1" ? (
                <h1 style={{ whiteSpace: "initial" }} key={ind}>
                  {cnt.value}
                </h1>
              ) : cnt.type === "h2" ? (
                <h2 key={ind}>{cnt.value}</h2>
              ) : cnt.type === "h3" ? (
                <h3 key={ind}>{cnt.value}</h3>
              ) : cnt.type === "h4" ? (
                <h4 key={ind}>{cnt.value}</h4>
              ) : cnt.type === "h5" ? (
                <h5 key={ind}>{cnt.value}</h5>
              ) : cnt.type === "h6" ? (
                <h6 key={ind}>{cnt.value}</h6>
              ) : cnt.type === "p" ? (
                <div key={ind}>
                  {cnt.value.split("<br>").map((it, id) => (
                    <p key={id}>{it}</p>
                  ))}
                </div>
              ) : cnt.type === "a" ? (
                <a key={ind} href={cnt.href}>
                  {cnt.value}
                </a>
              ) : (
                ""
              )
            )}
          </div> */}
          <div style={{ marginTop: "5rem" }}>
            {rows.filter((itm) => itm.id !== title).length > 0 && (
              <h4 style={{ textAlign: "center" }}>More Events</h4>
            )}
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              autoPlay={false}
              infinite={false}
              className="moreCategories"
              responsive={responsive}
            >
              {rows
                .filter((itm) => itm.id !== title)
                .map((eventItem, ind) => (
                  <div key={ind} className="card">
                    <img
                      className="bgImage"
                      src={eventItem.picture}
                      alt="links"
                    />

                    <div className="content">
                      <h1>{eventItem?.nameOfBrand}</h1>
                    </div>
                    <button
                      onClick={() =>
                        navigate(
                          `/events/${eventItem.nameOfBrand}/${eventItem.docId}`
                        )
                      }
                    >
                      Visit Here
                    </button>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
