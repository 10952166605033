import React from "react";
import "./listing.css";
import heroBg1 from "../../assets/images/hero-bg-1.jpg";
import heroBg2 from "../../assets/images/hero-bg-2.jpg";
import heroBg3 from "../../assets/images/hero-bg-3.jpg";
import heroBg4 from "../../assets/images/hero-bg-4.jpg";
import heroBg5 from "../../assets/images/hero-bg-5.jpg";
import more from "../../assets/images/more.jpg";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const Listing = () => {
  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();
  const navigate = useNavigate();
  return (
    <div id="listings" className="listingWrapper">
      <div className="header">
        <h1>Directory</h1>
        <p>
          Our ways of buying could be more conscious and locally sourced...
          Let's address that here. You have more sustainable brands, products,
          and services to suggest?
          <a href="/#contactus"> Contact us.</a>
        </p>
      </div>
      <div className="listing">
        {[
          { img: heroBg1, title: "Arts and Crafts" },
          { img: heroBg2, title: "Organic and Local Food" },
          { img: heroBg3, title: "Well Being and Lifestyle" },
          { img: heroBg4, title: "Ecotourism" },
          { img: heroBg5, title: "Recycling and Second Hand" },
          { img: more, title: "ALL" },
        ]
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((itm, ind) => (
            <div key={ind} className="listBox">
              <img alt={itm.title} src={itm.img} />
              <h3>{itm.title}</h3>
              <button
                style={{ marginTop: "4px" }}
                onClick={() => {
                  trackEvent(itm.title, "clicked_on_category", itm.title);
                  navigate(`/directory/${itm.title}/all`);
                }}
              >
                Explore
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Listing;
