import React from "react";

import "./error.css";
import { useNavigate } from "react-router-dom";
import error from "../../assets/images/error.svg";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="errorWrapper">
      <div>
        <h1 className="title">Something is not right...</h1>
        <h2 c="dimmed" size="lg">
          Page you are trying to open does not exist. You may have mistyped the
          address, or the page has been moved to another URL. If you think this
          is an error contact support.
        </h2>
        <button
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
          variant="filled"
          size="md"
          mt="xl"
          className="control"
        >
          Get back to home page
        </button>
      </div>
      <img alt="error" src={error} className="desktopImage" />
    </div>
  );
};

export default PageNotFound;
