import React, { useState, useEffect } from "react";
import Founder from "../../components/founder/founder";
import Hero from "../../components/hero/hero";
import Links from "../../components/links/links";
import "./home.css";
import Listing from "../../components/listing/listing";
import {
  fetchProfileDataFromFirestore,
  getAllEventsFromFirestore,
  getSEOById,
  getSliderById,
} from "../../firebase/functions";
import Helmet from "react-helmet";
import Contact from "../../components/contact/contact";
import WhyUs from "../../components/whyus/whyus";
import { useNavigate } from "react-router-dom";
import GoogleMap from "../../components/googlemap/googlemap";

import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import heroBg1 from "../../assets/images/hero-bg-1.jpg";
import heroBg2 from "../../assets/images/hero-bg-2.jpg";
import heroBg3 from "../../assets/images/hero-bg-3.jpg";
import heroBg4 from "../../assets/images/hero-bg-4.jpg";
import heroBg5 from "../../assets/images/hero-bg-5.jpg";

const initialData = {
  fullName: "",
  linkedin: "",
  bio: "",
  profilePicture: "",
};

const PAGE_SIZE = 6; // Adjust the page size as per your requirements

const Home = () => {
  const [formData, setFormData] = useState(initialData);
  const [dataFetched, setDataFetched] = useState(false);
  const [initialLoader, setinitialLoader] = useState(true);
  const [rows, setrows] = useState([]);
  const [images, setImages] = useState([
    heroBg1,
    heroBg2,
    heroBg3,
    heroBg4,
    heroBg5,
  ]);
  const navigate = useNavigate();

  const [seoContent, setseoContent] = useState({
    title: "Green Links",
    description: "",
    metaTag: "",
  });

  useEffect(() => {
    (async () => {
      const seo = await getSEOById({ id: "home" });
      if (seo === null) {
        setseoContent({
          title: "Green Links",
          description: "",
          metaTag: "",
        });
      } else {
        setseoContent(seo);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const imageData = await getSliderById();
      if (imageData === null) {
        toast.error("Error in fetching data");
      } else {
        const extractedUrls = imageData.images.map(
          (imageObject) => imageObject.url
        );
        setImages(extractedUrls);
      }
    })();
    const fetchProfileData = async () => {
      if (!dataFetched) {
        const profileData = await fetchProfileDataFromFirestore(
          "gzA7eUa5DnZAAX6oqUn9lvs2XYm2"
        );
        const eventData = await getAllEventsFromFirestore(PAGE_SIZE, null);
        if (eventData !== null) {
          setrows(eventData.data);
        }
        if (profileData !== null) {
          setFormData(profileData);
        }

        if (eventData === null || profileData === null) {
          navigate("/error");
          toast.error("Error occured try again later");
        }
        setinitialLoader(false);
        setDataFetched(true);
      } else {
        setinitialLoader(false);
      }
    };

    fetchProfileData();
  }, [dataFetched]); // Add dataFetched to the dependency array

  return (
    <>
      <Helmet>
        <title>{seoContent.title}</title>
        <meta name="description" content={seoContent.description} />
        <meta name="keywords" content={seoContent.metaTag} />
      </Helmet>
      {initialLoader ? (
        <div className="home">
          <CircularProgress sx={{ margin: "auto" }} />
        </div>
      ) : (
        <>
          <Hero images={images} />
          <WhyUs />
          <Links rows={rows} />
          <Listing />
          <Founder formData={formData} />
          <GoogleMap />
          <Contact />
        </>
      )}
    </>
  );
};

export default Home;
