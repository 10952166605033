import React, { useState, useEffect, useRef } from "react";
import "./slider.css";
import { toast } from "react-toastify";
import {
  getSliderById,
  uploadImageToStorage,
  updateSliderInFirestore,
} from "../../firebase/functions";
import { CircularProgress } from "@mui/material";

const Slider = () => {
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [loader, setloader] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    (async () => {
      const imageData = await getSliderById();
      if (imageData === null) {
        toast.error("Error in fetching data");
      } else {
        setImages(imageData.images);
      }
    })();
  }, []);

  function revertImage() {
    setNewImage(null);
  }

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewImage(file);
    }
  };

  async function updateImage(id, index) {
    setloader(true);
    const url = await uploadImageToStorage(newImage, `/slider/${id}`);
    if (url === null) {
      setloader(false);
      toast.error("Error in updating image.");
    } else {
      const updatedContent = [...images];
      updatedContent[index] = { ...updatedContent[index], url }
      const rsult = await updateSliderInFirestore({ images: updatedContent });
      if (rsult === null) {
        setloader(false);
        toast.error("Error in updating image.");
      } else {
        setloader(false);
        setNewImage(null);
        toast.success(rsult);
        setImages(updatedContent);
      }
    }
  }

  return (
    <div className="slide">
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img
            alt={imageUrl}
            onClick={() => handleImageClick(index)}
            src={
              index === selectedImageIndex
                ? newImage !== null
                  ? URL.createObjectURL(newImage)
                  : imageUrl.url
                : imageUrl.url
            }
          />
          {selectedImageIndex === index && newImage !== null && (
            <div>
              <button onClick={revertImage}>revert</button>
              <button onClick={() => updateImage(imageUrl.id, index)}>
                {loader ? <CircularProgress /> : "upload"}
              </button>
            </div>
          )}
        </div>
      ))}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
};

export default Slider;
