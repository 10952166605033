import React from "react";
import "./founder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Founder = ({ formData }) => {
  return (
    <div className="founderContentWrapper">
      <h1>About</h1>
      <div id="aboutus">
        <div className="flex md:flex-row flex-col items-center justify-center py-10 w-[80%] mx-auto gap-4">
          <img
            alt="founder"
            width={250}
            height={250}
            className="rounded-full w-[250px] h-[250px]"
            src={formData.profilePicture}
          />
          <div>
            {formData.bio.split("<br>").map((p, index) => (
              <React.Fragment key={index}>
                <p>
                  {p}{" "}
                  {index === formData.bio.split("<br>").length - 2 && (
                    <a
                      href={formData.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        color="#222222"
                        size="2xl"
                      />
                    </a>
                  )}
                </p>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founder;
