import "./App.css";
import Navbar from "./components/navbar/navbar";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Footer from "./components/footer/footer";
import Directory from "./pages/directory/directory";
import Login from "./pages/admin/login";
import Home from "./pages/home/home";
import TermsAndCondition from "./pages/termsandcondition/termsandcondition";
import Event from "./pages/event/event";
import PageNotFound from "./pages/error/error";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

function App() {
  const [showNavbar, setshowNavbar] = useState(true);

  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("admin-panel")) {
      // Send pageview with a custom path
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: location.pathname,
      });
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.includes("admin-panel") ||
      location.pathname.includes("error")
    ) {
      setshowNavbar(false);
    }
  }, [location]);

  return (
    <div className="App">
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Directory />} path="/directory/:category/:name" />
        <Route element={<TermsAndCondition />} path="/terms-and-conditions" />
        <Route element={<Event />} path="/events/:name/:title" />
        <Route element={<Login />} path="/admin-panel/:state" />
        <Route element={<PageNotFound />} path="/error" />
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
      {showNavbar && <Footer />}
    </div>
  );
}

export default App;
