import React, { useState } from "react";
import "./navbar.css";
import DrawerComponent from "./drawer";
import { NavHashLink } from "react-router-hash-link";
import "./hamburger.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faContactBook,
  faEnvelope,
  faHomeUser,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showMobileMenu, setshowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setshowMobileMenu(!showMobileMenu);
  };

  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="logoParent">
        <img onClick={() => navigate("/")} alt="logo" src={logo} width={100} />
        {/* <p>Live Sustainably</p> */}
      </div>
      <DrawerComponent
        isDrawerOpen={showMobileMenu}
        closeDrawer={toggleMobileMenu}
      />
      <button
        onClick={toggleMobileMenu}
        className={`hamburger hamburger--elastic ${
          showMobileMenu ? "is-active" : ""
        }`}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <ul className="list">
        <li>
          <FontAwesomeIcon icon={faHomeUser} />
          <NavHashLink
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "end" })
            }
            smooth
            to="/"
          >
            home
          </NavHashLink>
        </li>
        <li>
          <FontAwesomeIcon icon={faContactBook} />
          <NavHashLink
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "end" })
            }
            smooth
            to="/#listings"
          >
            directory
          </NavHashLink>
        </li>
        <li>
          <FontAwesomeIcon icon={faInfoCircle} />
          <NavHashLink
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "end" })
            }
            smooth
            to="/#aboutus"
          >
            about
          </NavHashLink>
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} />
          <NavHashLink
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "end" })
            }
            smooth
            to="/#contactus"
          >
            contact
          </NavHashLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
