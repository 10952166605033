import React, { useRef } from "react";
import "./links.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 4,
  },
  smallLaptop: {
    breakpoint: { max: 1250, min: 952 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 925, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

const Links = ({ rows }) => {
  // Use useRef to get a reference to the Carousel component
  const carouselRef = useRef(null);

  const handleNextClick = () => {
    // Use the Carousel component's next function to go to the next slide
    carouselRef.current.next();
  };

  const navigate = useNavigate();

  const handlePrevClick = () => {
    // Use the Carousel component's previous function to go to the previous slide
    carouselRef.current.previous();
  };

  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();

  return (
    <div className="linking">
      <div className="header">
        <h1>Latest News & Events</h1>
        <a href="/events/all/all">view all</a>
        <div className="customButtons">
          <div className="commonClass leftIcon" onClick={handlePrevClick}>
            <div className="line-rounded-icon">
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
          </div>
          <div className="commonClass rightIcon" onClick={handleNextClick}>
            <div className="line-rounded-icon">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
        <span></span>
      </div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        responsive={responsive}
        focusOnSelect={true}
        pauseOnHover={true}
        className="eventSlider"
        infinite={true}
        ref={carouselRef} // Pass the ref to the Carousel component
      >
        {rows.map((eventItem, ind) => {
          return (
            <div key={ind} className="card">
              <img className="bgImage" src={eventItem.picture} alt="links" />

              <div className="content">
                <h1>{eventItem.nameOfBrand}</h1>
              </div>
              <button
                onClick={() => {
                  trackEvent(
                    eventItem.docId,
                    "clicked_on_event",
                    eventItem.nameOfBrand
                  );
                  navigate(
                    `/events/${eventItem.nameOfBrand}/${eventItem.docId}`
                  );
                }}
              >
                Visit Here
              </button>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Links;
