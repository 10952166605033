import React from "react";
import "./googlemap.css";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const GoogleMap = () => {
  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();
  return (
    <div className="maps">
      <h1>Locate what's near you</h1>
      <p>
        Click to get access to all directory’s contacts on this Google Maps list
      </p>
      <a
        target="_blank"
        onClick={() => {
          trackEvent(
            "google_maps_image",
            "clicked_on_google_maps",
            "https://maps.app.goo.gl/1V8MtzP7VSzRoQBq9"
          );
        }}
        rel="noreferrer"
        href="https://maps.app.goo.gl/1V8MtzP7VSzRoQBq9"
      >
        <div className="render"></div>
      </a>
    </div>
  );
};

export default GoogleMap;
