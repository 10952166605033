import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Admin from "./admin";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";
import { auth } from "../../firebase/firebase"; // Import the Firebase app
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useParams, useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [userid, setuserid] = useState("");

  const router = useParams();
  const navigate = useNavigate();

  // Function to send a password reset email
  const handleSendPasswordResetEmail = async (email) => {
    try {
      if (!email) {
        toast.error("Enter email please.");
        return;
      }
      // Send password reset email
      await sendPasswordResetEmail(auth, email);

      // Password reset email sent successfully
      toast.success("Password reset email sent successfully");
    } catch (error) {
      // Handle errors
      toast.error("Error sending password reset email:", error.message);
    }
  };

  useEffect(() => {
    const id = sessionStorage.getItem("userId")
    if (id) {
      if (router.state === "login") {
        navigate("/admin-panel/profile");
        return;
      }

      if (
        router.state !== "profile" &&
        router.state !== "links" &&
        router.state !== "directories" &&
        router.state !== "Slider" &&
        router.state !== "seo"
      ) {
        navigate("/");
        window.location.reload();
      } else {
        navigate("/admin-panel/" + router.state);
      }
      setuserid(id);
    } else {
      if (router.state !== "login") {
        navigate("/");
      }
      setuserid("wrong");
    }
  }, [router.state]);

  const handleLogin = async () => {
    try {
      setLoader(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userId = userCredential.user.uid;

      // Store user ID in session storage
      sessionStorage.setItem("userId", userId);
      setuserid(userId);
      toast.dismiss();
      toast.success("Login successful");
      setTimeout(() => {
        navigate("/admin-panel/profile");
      }, 2000);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      const errorCode = error.code;
      const errorMessage = error.message;

      // Handle different error codes
      toast.dismiss();
      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        toast.error("Invalid email or password");
      } else {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {userid.length === 0 ? (
        <CircularProgress />
      ) : userid !== "wrong" ? (
        <Admin />
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="button"
                fullWidth
                onClick={handleLogin}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loader ? <CircularProgress color="inherit" /> : "Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button
                    onClick={() => handleSendPasswordResetEmail(email)}
                    variant="body2"
                  >
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Add ToastContainer from react-toastify */}
        </Container>
      )}
    </div>
  );
}
