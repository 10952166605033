import React, { useState, useMemo, useRef, useEffect } from "react";
import "./links.css";
import "react-quill/dist/quill.snow.css";
import avatar from "../../assets/images/avatar.png";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import { Shimmer } from "react-shimmer";

import { v4 as uuidv4 } from "uuid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
// ** React Import
import {
  addBusinessToFirestore,
  getDirectoryByCategory,
  deleteBusinessAndImage,
  uploadImageToStorage,
  fetchAllImagesFromStorage,
  getDirectoryByCategoryASearch,
} from "../../firebase/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getColumns = (handleSelectRow, loaderForDelete) => {
  return [
    {
      field: "nameOfBrand",
      headerName: "Name of Brand",
      width: 200,
      renderCell: (params) => {
        return (
          <a
            href={`/directory/${params.row.category}/${params.row.nameOfBrand}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.value}
          </a>
        );
      },
    },
    { field: "category", headerName: "Category", minWidth: 150 },
    { field: "mail", headerName: "Mail", minWidth: 150 },

    {
      field: "webLink",
      headerName: "Web Link",
      width: 150,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          Visit
        </a>
      ),
      editable: true,
    },
    {
      field: "date",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <button
          className="button"
          onClick={() => handleSelectRow(params.row, "edit")}
        >
          Edit
        </button>
      ),
    },
    {
      field: "docId",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <button
          className="button"
          style={{ backgroundColor: "red" }}
          onClick={() => handleSelectRow(params.row, "delete")}
        >
          {loaderForDelete[0] && loaderForDelete[1] === params.row.docId ? (
            <CircularProgress />
          ) : (
            "Delete"
          )}
        </button>
      ),
    },
  ];
};

const ImgStyled = styled("img")(({ theme }) => ({
  width: 100,
  height: 100,
  marginRight: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const PAGE_SIZE = 31; // Adjust the page size as per your requirements

const Directory = () => {
  const [open, setOpen] = useState(false);
  const [imageFile, setimageFile] = useState(null);
  const [mail, setMail] = useState("");
  const [imgSrc, setImgSrc] = useState(avatar);
  const [inputValue, setInputValue] = useState("");
  const [nameOfBrand, setNameOfBrand] = useState("");
  const [googleMapLink, setGoogleMapLink] = useState("");
  const [rows, setrows] = useState([]);
  const [fetchLoader, setfetchLoader] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [webLink, setWebLink] = useState("");
  const [category, setcategory] = useState("ARTS AND CRAFTS");
  const [categoryForSearch, setcategoryForSearch] = useState("ALL");
  const [date, setdate] = useState(dayjs(Date.now()));
  const [fb, setfb] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [insta, setinsta] = useState("");
  const [currentSelectedRow, setcurrentSelectedRow] = useState(null);
  const [createNewLoader, setcreateNewLoader] = useState(false);
  const [lastDocument, setLastDocument] = useState(null);
  const [pageMode, setpageMode] = useState({ page: 0, pageSize: 30 });
  const [searchBusiness, setsearchBusiness] = useState("");
  const [loaderForSearch, setloaderForSearch] = useState(false);
  const [loaderForDelete, setloaderForDelete] = useState([false, ""]);
  const [loaderForMore, setloaderForMore] = useState(false);

  const [importedComponent, setImportedComponent] = useState(null);
  const [allImages, setallImages] = useState([]);
  const [file, setfile] = useState(null);
  const [loader, setloader] = useState(true);
  const [value, setValue] = useState("");
  const [uploadLoader, setuploadLoader] = useState(false);

  async function uploadFile() {
    if (file !== null) {
      setuploadLoader(true);
      const res = await uploadImageToStorage(file, `blog_images/${file.name}`);
      if (res !== null) {
        toast.success("image uploaded");
        (async () => {
          const res = await fetchAllImagesFromStorage();
          setallImages(res);
          setloader(false);
        })();
      } else {
        toast.error("Error occured");
      }
      setuploadLoader(false);
      setfile(null);
    }
  }

  const quillRef = useRef(null);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  function imageHandler() {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const value = prompt("Please enter the image URL");

    if (value && range) {
      editor.insertEmbed(range.index, "image", value, "user");
    }
  }

  useEffect(() => {
    const importComponent = async () => {
      const module = await import("react-quill");
      const ReactQuill = module.default;
      setImportedComponent(
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
        />
      );
    };

    importComponent();
  }, [modules, value, quillRef]);

  const handlePageChange = (params) => {
    if (
      pageMode.page < params.page &&
      rows.length < (params.page + 1) * params.pageSize
    ) {
      fetchMoreData(searchBusiness);
    }

    setpageMode(params);
  };

  useEffect(() => {
    // Define a timer variable to store the setTimeout ID
    let timer;

    const fetchDirectoryData = async () => {
      const directoryData = await getDirectoryByCategory(
        categoryForSearch,
        null,
        PAGE_SIZE
      );
      if (directoryData !== null) {
        setrows(directoryData.data);
        if (directoryData.lastDoc === undefined) {
          setLastDocument(null);
        } else {
          setLastDocument(directoryData.lastDoc);
        }
      }
      setfetchLoader(false);
    };

    // Function to fetch events
    const fetchEventsBySearch = async () => {
      setloaderForSearch(true);
      const result = await getDirectoryByCategoryASearch(
        categoryForSearch,
        searchBusiness,
        null,
        PAGE_SIZE
      );
      setloaderForSearch(false);
      if (result) {
        setrows(result.data);
        if (result.lastDoc === undefined) {
          setLastDocument(null);
        } else {
          setLastDocument(result.lastDoc);
        }
      } else {
        toast.error("Error occured, try again later.");
      }
    };

    // Debounce the fetch function
    const debouncedFetch = () => {
      // Clear the existing timer if it exists
      if (timer) {
        clearTimeout(timer);
      }

      // Set a new timer to call the fetch function after 1000 milliseconds
      timer = setTimeout(fetchEventsBySearch, 1000); // Adjust the delay as needed
    };

    if (searchBusiness.length === 0) {
      fetchDirectoryData();
    } else {
      debouncedFetch();
    }

    (async () => {
      const res = await fetchAllImagesFromStorage();
      setallImages(res);
      setloader(false);
    })();

    // Cleanup the timer on component unmount
    return () => {
      clearTimeout(timer);
    };
  }, [searchBusiness, categoryForSearch]);

  const fetchMoreData = async (searchBusiness) => {
    if (lastDocument === null) return;
    setloaderForMore(true);
    let directoryData = null;
    if (searchBusiness.length === 0) {
      directoryData = await getDirectoryByCategory(
        categoryForSearch,
        lastDocument,
        PAGE_SIZE
      );
    } else {
      directoryData = await getDirectoryByCategoryASearch(
        categoryForSearch,
        searchBusiness,
        lastDocument,
        PAGE_SIZE
      );
    }
    if (directoryData !== null) {
      setrows((prevRows) => [...prevRows, ...directoryData.data]);
      if (directoryData.lastDoc === undefined) {
        setLastDocument(null);
      } else {
        setLastDocument(directoryData.lastDoc);
      }
    } else {
      toast.error("Error occured, try again");
    }
    setloaderForMore(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const isImage = (file) => {
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"]; // Add more types if needed
    return allowedImageTypes.includes(file.type);
  };

  const handleInputImageChange = (file) => {
    const reader = new FileReader();
    const { files } = file.target;
    if (files && files.length !== 0) {
      reader.onload = () => setImgSrc(reader.result);
      reader.readAsDataURL(files[0]);
      setimageFile(files[0]);
      if (reader.result !== null) {
        setInputValue(reader.result);
      }
    }
  };
  const handleInputImageReset = () => {
    setInputValue("");
    setimageFile(null);
    setImgSrc(avatar);
  };

  const handleClose = () => {
    setOpen(false);
    setMail("");
    setNameOfBrand("");
    setInputValue("");
    setimageFile(null);
    setdate(dayjs(Date.now()));
    setImgSrc(avatar);
    setcurrentSelectedRow(null);

    setValue("");
    setWebLink("");
    setGoogleMapLink("");
    setPhoneNumber("");
  };

  const handleSaveChanges = async (currentSelectedRow) => {
    if (
      !mail &&
      !phoneNumber &&
      !webLink &&
      !googleMapLink &&
      !nameOfBrand &&
      imageFile === null
    ) {
      toast.error("Fields are required");
      return;
    }
    setcreateNewLoader(true);
    let downloadURL = null;
    const uniqueDocId =
      currentSelectedRow === null ? uuidv4() : currentSelectedRow.docId;

    if (imageFile) {
      if (!isImage(imageFile)) {
        setcreateNewLoader(false);
        toast.error("Only Image are acceptable as profile picture.");
        return;
      }
      // Upload the image to Firebase Storage
      downloadURL = await uploadImageToStorage(
        imageFile,
        `directory/${uniqueDocId}`
      );

      if (downloadURL !== null) {
        setImgSrc(downloadURL);
        // Set the download URL or use it as needed in your component
      } else {
        toast.error("Error occured, try again later");
        setcreateNewLoader(false);
      }
    }

    const result = await addBusinessToFirestore(
      {
        mail: mail,
        nameOfBrand: nameOfBrand.toLowerCase(),
        googleMapLink: googleMapLink,
        phoneNumber: phoneNumber,
        category: category,
        webLink: webLink,
        picture: downloadURL !== null ? downloadURL : imgSrc,
        date: date.toISOString(),
        content: value,
        docId: uniqueDocId,
        fb: fb,
        linkedin: linkedin,
        insta: insta,
      },
      setcreateNewLoader,
      currentSelectedRow === null ? false : true
    );
    if (result === null) {
      toast.error("Error Occured, try again");
    } else {
      toast.success("New Member Saved");
    }
    handleClose();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  async function handleSelectRow(row, reason) {
    if (reason === "edit") {
      setcurrentSelectedRow(row);
      setImgSrc(row.picture);
      setMail(row.mail);
      setdate(dayjs(new Date(row.date).getTime()));
      setcategory(row.category);
      setNameOfBrand(row.nameOfBrand);
      setWebLink(row.webLink);
      setValue(row.content);
      setGoogleMapLink(row.googleMapLink);
      setPhoneNumber(row.phoneNumber);
      setinsta(row.insta);
      setlinkedin(row.linkedin);
      setfb(row.fb);
      handleClickOpen();
    } else if (reason === "delete") {
      setloaderForDelete([true, row.docId]);
      const response = await deleteBusinessAndImage(
        row.docId,
        `directory/${row.docId}`
      );

      setloaderForDelete([false, ""]);
      if (response === null) {
        toast.error("Error occured try again");
      } else {
        toast.error(response);
      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  return (
    <div className="links">
      {/* Add ToastContainer from react-toastify */}

      {fetchLoader ? (
        <CircularProgress />
      ) : (
        <>
          <div className="header">
            <div className="headerTitle">
              <h1>Manage Directory Here</h1>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryForSearch}
                fullWidth
                label="Category"
                onChange={(e) => setcategoryForSearch(e.target.value)}
              >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                <MenuItem value={"ARTS AND CRAFTS"}>ARTS AND CRAFTS</MenuItem>
                <MenuItem value={"ORGANIC AND LOCAL FOOD"}>
                  ORGANIC AND LOCAL FOOD
                </MenuItem>
                <MenuItem value={"WELL BEING AND LIFESTYLE"}>
                  WELL BEING AND LIFESTYLE
                </MenuItem>
                <MenuItem value="ECOTOURISM">ECOTOURISM</MenuItem>
                <MenuItem value="RECYCLING AND SECOND HAND">
                  RECYCLING AND SECOND HAND
                </MenuItem>
              </Select>
              <input
                value={searchBusiness}
                onChange={(e) => setsearchBusiness(e.target.value)}
                placeholder="Search Brand Name"
              />
            </div>
            <button onClick={handleClickOpen} className="button">
              Add New
            </button>
          </div>
          <Dialog
            open={open}
            fullScreen={true}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent sx={{ padding: "25px 0px 0px" }}>
              <div className="closeDialog" onClick={handleClose}>
                <FontAwesomeIcon icon={faX} />
              </div>
              <Card sx={{ padding: "12px" }}>
                <Container component="main">
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ImgStyled src={imgSrc} alt="Profile Pic" />
                      <div>
                        <ButtonStyled
                          component="label"
                          variant="contained"
                          htmlFor="account-settings-upload-image"
                        >
                          Upload New Photo
                          <input
                            hidden
                            type="file"
                            value={inputValue}
                            accept="image/png, image/jpeg"
                            onChange={handleInputImageChange}
                            id="account-settings-upload-image"
                          />
                        </ButtonStyled>
                        <ResetButtonStyled
                          color="secondary"
                          variant="tonal"
                          onClick={handleInputImageReset}
                        >
                          Reset
                        </ResetButtonStyled>
                        <Typography sx={{ mt: 4, color: "text.disabled" }}>
                          Allowed PNG or JPEG. Max size of 800K.
                        </Typography>
                      </div>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CssBaseline />
                  <Box
                    sx={{
                      marginBottom: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box noValidate sx={{ mt: 1 }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        fullWidth
                        label="Category"
                        onChange={(e) => setcategory(e.target.value)}
                      >
                        <MenuItem value={"ARTS AND CRAFTS"}>
                          ARTS AND CRAFTS
                        </MenuItem>
                        <MenuItem value={"ORGANIC AND LOCAL FOOD"}>
                          ORGANIC AND LOCAL FOOD
                        </MenuItem>
                        <MenuItem value={"WELL BEING AND LIFESTYLE"}>
                          WELL BEING AND LIFESTYLE
                        </MenuItem>
                        <MenuItem value="ECOTOURISM">ECOTOURISM</MenuItem>
                        <MenuItem value="RECYCLING AND SECOND HAND">
                          RECYCLING AND SECOND HAND
                        </MenuItem>
                      </Select>
                      {/* Add necessary TextFields and attach them to useState hooks */}
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="mail"
                        label="Mail"
                        autoComplete="mail"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="nameOfBrand"
                        label="Name of Brand"
                        autoComplete="nameOfBrand"
                        value={nameOfBrand}
                        onChange={(e) => setNameOfBrand(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="googleMapLink"
                        label="Google Map Link"
                        autoComplete="googleMapLink"
                        value={googleMapLink}
                        onChange={(e) => setGoogleMapLink(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        autoComplete="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="webLink"
                        label="Web Link"
                        autoComplete="webLink"
                        value={webLink}
                        onChange={(e) => setWebLink(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="Facebook"
                        label="Facebook handle"
                        autoComplete="facebook"
                        value={fb}
                        onChange={(e) => setfb(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="insta"
                        label="Instagram handle"
                        autoComplete="insta"
                        value={insta}
                        onChange={(e) => setinsta(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="Linkedin"
                        label="Linkedin handle"
                        autoComplete="Linkedin"
                        value={linkedin}
                        onChange={(e) => setlinkedin(e.target.value)}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Date"
                            value={date}
                            sx={{ width: "100%" }}
                            onChange={(newValue) => setdate(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* Dynamic text fields */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          marginY: "10px",
                        }}
                      >
                        <React.Suspense>
                          {importedComponent ? (
                            importedComponent
                          ) : (
                            <div>Loading...</div>
                          )}
                        </React.Suspense>
                        <div className="flex flex-col w-[300px] h-[100vh] overflow-auto border-2 border-black">
                          <div>
                            <TextField
                              accept="image/*" // Only accept image files
                              type="file"
                              onChange={(e) => setfile(e.target.files[0])}
                            />
                            {file !== null && (
                              <button
                                onClick={uploadFile}
                                className="bg-blue-500 hover:bg-blue-700 my-2 mx-12 text-white font-bold py-2 px-4 rounded"
                              >
                                {uploadLoader ? <CircularProgress /> : "Save?"}
                              </button>
                            )}
                          </div>

                          <h1 className="my-3 mx-auto">Images In Storage</h1>
                          {loader ? (
                            <CircularProgress />
                          ) : (
                            allImages.map((img, id) => (
                              <div
                                key={id}
                                className="flex my-3 flex-col items-center justify-center gap-2"
                              >
                                <img alt="images" src={img} width={"300px"} />
                                <button
                                  onClick={() => {
                                    window.navigator.clipboard
                                      .writeText(img)
                                      .then(() => {
                                        toast.success("copied to clipboard");
                                      });
                                  }}
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  Copy
                                </button>
                              </div>
                            ))
                          )}
                        </div>

                        {/* <CustomTextField
                          rows={3}
                          multiline
                          label={"Write Description"}
                          value={content}
                          onChange={(e) =>
                            handleChangeTextField(e.target.value)
                          }
                          id="textarea-outlined-static"
                        /> */}
                      </Box>

                      <Button
                        type="button"
                        fullWidth
                        onClick={() => handleSaveChanges(currentSelectedRow)}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {createNewLoader ? (
                          <CircularProgress color="inherit" />
                        ) : currentSelectedRow === null ? (
                          "Create New"
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Container>
              </Card>
            </DialogContent>
          </Dialog>
          <Box sx={{ height: 400, width: "100%" }}>
            {loaderForSearch || fetchLoader || loaderForMore ? (
              <Shimmer height={400} />
            ) : (
              <DataGrid
                rows={rows}
                onPaginationModelChange={handlePageChange}
                paginationModel={pageMode}
                columns={getColumns(handleSelectRow, loaderForDelete)}
                disableRowSelectionOnClick
              />
            )}
          </Box>
        </>
      )}
    </div>
  );
};

export default Directory;
