import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import "./admin.css";
import sidebar from "../../assets/images/sidebar.jpg";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Profile from "./profile";
import Directory from "./directory";
import { useNavigate, useParams } from "react-router-dom";
import Events from "./links";
import Slider from "./slider";
import SEO from "./seo";

const Admin = () => {
  const [toggled, setToggled] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("profile");

  const navigate = useNavigate();
  const params = useParams();
  const handleMenuItemClick = (menuItem) => {
    navigate("/admin-panel/" + menuItem);
    setSelectedMenuItem(menuItem);
    setToggled(false);
  };

  useEffect(() => {
    if (
      params.state !== "profile" &&
      params.state !== "links" &&
      params.state !== "directories" &&
      params.state !== "Slider" &&
      params.state !== "seo"
    ) {
      navigate("/");
    } else {
      setSelectedMenuItem(params.state);
      navigate("/admin-panel/" + params.state);
    }
  }, [params.state]);

  const renderMainContent = () => {
    switch (selectedMenuItem) {
      case "profile":
        return <Profile />;
      case "links":
        return <Events />;
      case "directories":
        return <Directory />;
      case "Slider":
        return <Slider />;
      case "seo":
        return <SEO />;
      default:
        return null;
    }
  };

  return (
    <div className="adminWrapper">
      <Sidebar
        id="sidebar"
        image={sidebar}
        style={{ height: "100%" }}
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="sm"
      >
        <Menu>
          <MenuItem>
            <button
              className="sb-button-close"
              onClick={() => setToggled(!toggled)}
            >
              <FontAwesomeIcon icon={faX} />
            </button>
          </MenuItem>
          <SubMenu label="Profile">
            <MenuItem
              onClick={() => handleMenuItemClick("profile")}
              active={selectedMenuItem === "profile"}
            >
              View Profile
            </MenuItem>
          </SubMenu>
          <SubMenu label="Events & Links">
            <MenuItem
              onClick={() => handleMenuItemClick("links")}
              active={selectedMenuItem === "links"}
            >
              View Links
            </MenuItem>
          </SubMenu>
          <SubMenu label="Directory">
            <MenuItem
              onClick={() => handleMenuItemClick("directories")}
              active={selectedMenuItem === "directories"}
            >
              View Members
            </MenuItem>
          </SubMenu>
          <SubMenu label="Slider">
            <MenuItem
              onClick={() => handleMenuItemClick("Slider")}
              active={selectedMenuItem === "Slider"}
            >
              Manage Slider
            </MenuItem>
          </SubMenu>
          <SubMenu label="SEO Management">
            <MenuItem
              onClick={() => handleMenuItemClick("seo")}
              active={selectedMenuItem === "seo"}
            >
              SEO Management
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
      <main>
        <div>
          {!toggled && (
            <button className="sb-button" onClick={() => setToggled(!toggled)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          )}
        </div>
        {renderMainContent()}
      </main>
    </div>
  );
};

export default Admin;
