import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <h1 onClick={() => navigate("/")} className="logo">
        the green links{" "}
      </h1>
      <a href="/terms-and-conditions">Terms & Conditions</a>
      <a href="mailto:thegreenlinks@gmail.com">Contact Us</a>
      <p>Copyright @2023</p>
    </div>
  );
};

export default Footer;
