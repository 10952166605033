import React, { useState, useEffect } from "react";
import "./directory.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import heroBg1 from "../../assets/images/hero-bg-1.jpg";
import heroBg2 from "../../assets/images/hero-bg-2.jpg";
import heroBg3 from "../../assets/images/hero-bg-3.jpg";
import heroBg4 from "../../assets/images/hero-bg-4.jpg";
import heroBg5 from "../../assets/images/hero-bg-5.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import * as noData from "../../assets/images/noData.json";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {
  faEnvelope,
  faLink,
  faLocationPin,
  faPhone,
  faShare,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDirectoryByCategory,
  getDirectoryByCategoryASearch,
  getSEOById,
} from "../../firebase/functions";
import Helmet from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useAnalyticsEventTracker from "../../hooks/useAnalytic";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 3,
  },
  smallLaptop: {
    breakpoint: { max: 1250, min: 952 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 925, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: noData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

// Styled component for the image
const Img = styled("img")(({ theme }) => ({
  height: "11rem",
  border: "1px solid black",
  borderRadius: theme.shape.borderRadius,
}));

const PAGE_SIZE = 25; // Adjust the page size as per your requirements

const Directory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const categoryForSearch = params.category;
  const [searchBusiness, setsearchBusiness] = useState("");
  const [loaderForMore, setloaderForMore] = useState(false);
  const [currentSelection, setcurrentSelection] = useState(null);
  const [rows, setrows] = useState([]);
  const [loaderForSearch, setloaderForSearch] = useState(false);
  const [fetchLoader, setfetchLoader] = useState(true);
  const [showMobile, setshowMobile] = useState(false);
  const [lastDocument, setLastDocument] = useState(null);
  const [seoContent, setseoContent] = useState({
    title: "Green Links",
    description: "",
    metaTag: "",
  });

  // Create an instance of the useAnalyticsEventTracker hook
  const trackEvent = useAnalyticsEventTracker();

  useEffect(() => {
    (async () => {
      const seo = await getSEOById({ id: "home" });
      if (seo === null) {
        setseoContent({
          title: "Green Links",
          description: "",
          metaTag: "",
        });
      } else {
        setseoContent(seo);
      }
    })();
  }, []);

  const [isTyping, setIsTyping] = useState(false);

  const handleInputChange = (event) => {
    // Set isTyping to true when the user starts typing
    setIsTyping(true);
  };

  const handleInputBlur = () => {
    // Set isTyping to false when the input loses focus
    setIsTyping(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  useEffect(() => {
    if (!params.name || !params.category) {
      navigate("/");
    }
  }, [params]);

  useEffect(() => {
    if (params.name !== "all") {
      setsearchBusiness(params.name);
    }
  }, [params.name]);

  const fetchDirectoryData = async () => {
    const directoryData = await getDirectoryByCategory(
      categoryForSearch,
      null,
      PAGE_SIZE
    );
    if (directoryData !== null) {
      setrows(directoryData.data);
      if (directoryData.lastDoc === undefined) {
        setLastDocument(null);
      } else {
        setLastDocument(directoryData.lastDoc);
      }
    }
    setfetchLoader(false);
  };

  // Function to fetch events
  const fetchEventsBySearch = async (searchBusiness) => {
    setloaderForSearch(true);
    const result = await getDirectoryByCategoryASearch(
      categoryForSearch,
      searchBusiness,
      null,
      PAGE_SIZE
    );
    setloaderForSearch(false);
    if (result) {
      if (result.data.length === 1 && !isTyping) {
        handleClickOpen(result.data[0]);
      }
      setrows(result.data);
      if (result.lastDoc === undefined) {
        setLastDocument(null);
      } else {
        setLastDocument(result.lastDoc);
      }
    } else {
      toast.error("Error occured, try again later.");
    }
  };

  // Debounce the fetch function
  const debouncedFetch = (timer) => {
    // Clear the existing timer if it exists
    if (timer) {
      clearTimeout(timer);
    }

    // Set a new timer to call the fetch function after 1000 milliseconds
    timer = setTimeout(() => fetchEventsBySearch(searchBusiness), 1000); // Adjust the delay as needed
  };

  useEffect(() => {
    // Define a timer variable to store the setTimeout ID
    let timer;

    if (searchBusiness.length === 0) {
      fetchDirectoryData();
    } else {
      debouncedFetch(timer);
    }

    scrollToTop();
    // Cleanup the timer on component unmount
    return () => {
      clearTimeout(timer);
    };
  }, [searchBusiness, categoryForSearch]);

  const handleClickOpen = (row) => {
    setcurrentSelection(row);
    trackEvent(row.category, "clicked_on_brand_popup", row.nameOfBrand);
  };

  const handleClose = () => {
    setcurrentSelection(null);
  };

  const fetchMoreData = async (searchBusiness) => {
    if (lastDocument === null) return;
    setloaderForMore(true);
    let directoryData = null;
    if (searchBusiness.length === 0) {
      directoryData = await getDirectoryByCategory(
        categoryForSearch,
        lastDocument,
        PAGE_SIZE
      );
    } else {
      directoryData = await getDirectoryByCategoryASearch(
        categoryForSearch,
        searchBusiness,
        lastDocument,
        PAGE_SIZE
      );
    }
    if (directoryData !== null) {
      setrows((prevRows) => [...prevRows, ...directoryData.data]);
      if (directoryData.lastDoc === undefined) {
        setLastDocument(null);
      } else {
        setLastDocument(directoryData.lastDoc);
      }
    } else {
      toast.error("Error occured, try again");
    }
    setloaderForMore(false);
  };

  return (
    <div className="directoryContentParent">
      <Helmet>
        <title>{seoContent.title}</title>
        <meta name="description" content={seoContent.description} />
        <meta name="keywords" content={seoContent.metaTag} />
      </Helmet>
      {fetchLoader ? (
        <div className="wrapper">
          <CircularProgress />
        </div>
      ) : (
        <div className="directoryContent">
          <h1>{categoryForSearch}</h1>
          <input
            value={searchBusiness}
            onBlur={handleInputBlur}
            onChange={(e) => {
              setsearchBusiness(e.target.value);
              handleInputChange();
            }}
            placeholder="Search Brand Name"
            className="eventInputField"
          />
          {loaderForSearch ? (
            <div className="wrapper">
              <CircularProgress />
            </div>
          ) : rows.length === 0 ? (
            <div className="noDataImage">
              <Lottie options={defaultOptions} height={400} width={300} />
            </div>
          ) : (
            <div className="directory">
              {currentSelection !== null && (
                <Dialog
                  open={currentSelection !== null}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => {
                    handleClose();
                    setshowMobile(false);
                  }}
                  fullWidth
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <div className="closeDialog" onClick={handleClose}>
                      <FontAwesomeIcon icon={faX} />
                    </div>
                    <Card
                      className="dialogContentBack"
                      sx={{ padding: "12px" }}
                    >
                      <Img
                        sx={{
                          maxHeight: "200px",
                        }}
                        alt="Stumptown Roasters"
                        src={currentSelection?.picture}
                      />

                      <Box
                        sx={{
                          mb: 4,
                          backgroundColor: "white",
                          padding: "3px",
                          wordWrap: "break-word",
                          width: "100%",
                          borderRadius: "8px",
                          marginTop: "20px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {currentSelection.nameOfBrand}
                        </Typography>
                        <div id="contentitis">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: currentSelection?.content,
                            }}
                          />
                        </div>

                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "20px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {currentSelection.webLink && (
                              <Typography>
                                <a
                                  style={{ color: "#304d50" }}
                                  href={currentSelection.webLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faLink} />
                                </a>
                              </Typography>
                            )}
                            {currentSelection.googleMapLink && (
                              <Typography>
                                <a
                                  style={{ color: "#304d50" }}
                                  href={currentSelection.googleMapLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon={faLocationPin} />
                                </a>
                              </Typography>
                            )}
                            {currentSelection.fb && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={currentSelection.fb}
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  color="#304d50"
                                  icon={faFacebook}
                                />
                              </a>
                            )}
                            {currentSelection.insta && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={currentSelection.insta}
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  color="#304d50"
                                  icon={faInstagram}
                                />
                              </a>
                            )}
                            {currentSelection.linkedin && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={currentSelection.linkedin}
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  color="#304d50"
                                  icon={faLinkedin}
                                />
                              </a>
                            )}
                            {currentSelection.mail && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={"mailto:" + currentSelection.mail}
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  color="#304d50"
                                  icon={faEnvelope}
                                />
                              </a>
                            )}
                            {currentSelection.phoneNumber && (
                              <div
                                onClick={() => {
                                  try {
                                    window.navigator.clipboard.writeText(
                                      currentSelection.phoneNumber
                                    );
                                    toast.success("copied to clipboard");
                                  } catch (error) {
                                    toast.error("Failed to copy");
                                  }
                                }}
                                id="business_number"
                              >
                                <FontAwesomeIcon
                                  size="xl"
                                  onClick={() => setshowMobile(true)}
                                  color="#304d50"
                                  icon={faPhone}
                                />
                                {showMobile && (
                                  <p>{currentSelection.phoneNumber}</p>
                                )}
                              </div>
                            )}
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                try {
                                  window.navigator.clipboard.writeText(
                                    `${window.location.origin}/directory/${currentSelection.category}/${currentSelection.nameOfBrand}`
                                  );
                                  toast.success("Link Copied to clipboard");
                                } catch (error) {
                                  toast.error("failed to copy");
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                size="xl"
                                color="#304d50"
                                icon={faShare}
                              />
                            </div>
                          </Box>
                        </CardContent>
                      </Box>
                    </Card>
                  </DialogContent>
                </Dialog>
              )}
              {rows
                .sort((a, b) => a.nameOfBrand.localeCompare(b.nameOfBrand))
                .map((itm, ind) => {
                  return (
                    <div key={ind} className="card">
                      <img
                        className="bgImage"
                        alt="bgImage"
                        src={itm.picture}
                      />
                      <div className="content">
                        <h1>{itm.nameOfBrand}</h1>
                        {/* <p>
                          {itm.content.length > 50
                            ? itm.content.substring(0, 50) + "..."
                            : itm.content}
                        </p> */}
                      </div>
                      <button onClick={() => handleClickOpen(itm)}>
                        Know More
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
          {rows.length > 0 && lastDocument !== null && (
            <button
              className="fetchMoreButton"
              onClick={() => fetchMoreData(searchBusiness)}
            >
              {loaderForMore ? (
                <CircularProgress sx={{ margin: "auto" }} />
              ) : (
                "Fetch More"
              )}
            </button>
          )}
          <h4>More Categories to browse</h4>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            autoPlay={false}
            infinite={false}
            className="moreCategories"
            responsive={responsive}
          >
            {[
              { img: heroBg1, title: "ARTS AND CRAFTS" },
              { img: heroBg2, title: "ORGANIC AND LOCAL FOOD" },
              { img: heroBg3, title: "WELL BEING AND LIFESTYLE" },
              { img: heroBg4, title: "ECOTOURISM" },
              { img: heroBg5, title: "RECYCLING AND SECOND HAND" },
            ]
              .filter((itm) => itm.title !== categoryForSearch)
              .map((itm, ind) => (
                <div key={ind} className="listBox">
                  <img alt={itm.title} src={itm.img} />
                  <h3>{itm.title}</h3>
                  <button
                    onClick={() => navigate(`/directory/${itm.title}/all`)}
                  >
                    Explore
                  </button>
                </div>
              ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default Directory;
