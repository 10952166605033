import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import { updateOrCreateSEO, getSEOById } from "../../firebase/functions";
import "./seo.css";
import { toast } from "react-toastify";

const SEO = () => {
  const [links, setlinks] = useState("/home");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [metaTag, setmetaTag] = useState("");
  const [fetchLoader, setfetchLoader] = useState(false);
  const [loader, setloader] = useState(false);

  const handleChange = (event) => {
    setlinks(event.target.value);
  };

  useEffect(() => {
    (async () => {
      setfetchLoader(true);
      const data = await getSEOById(links);
      setfetchLoader(false);
      if (data === null) {
        toast.error("Error occured, try again later");
        return;
      } else if (data === "no-doc") {
        setdescription("");
        settitle("");
        setmetaTag("");
        return;
      } else {
        setdescription(data.description);
        settitle(data.title);
        setmetaTag(data.metaTag);
      }
    })();
  }, [links]);

  useEffect(() => {
    if (fetchLoader) {
      toast.loading("We are fetching...");
    } else {
      toast.dismiss();
    }
  }, [fetchLoader]);

  async function sentForm(e) {
    e.preventDefault();

    if (!title && !description && !metaTag) {
      toast.error("Please fill atleast 1 field");
      return;
    }
    setloader(true);
    const result = await updateOrCreateSEO(links, title, description, metaTag);
    setloader(false);
    if (result) {
      toast.success(result);
    } else {
      toast.error("Failed, try again later");
    }
  }

  return (
    <div className="container">
      <Box
        component="form"
        onSubmit={sentForm}
        noValidate
        className="formBox"
        autoComplete="off"
      >
        <h1>Manage SEO For Green Links</h1>
        <FormControl sx={{ width: "100%" }} size="small">
          <InputLabel id="demo-select-small-label">Links</InputLabel>
          <Select
            sx={{ backgroundColor: "white" }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={links}
            label="Links"
            onChange={handleChange}
          >
            <MenuItem value="/home">
              <em>Home</em>
            </MenuItem>
            <MenuItem value={"/events"}>/Events</MenuItem>
            <MenuItem value={"/directory"}>/Directory</MenuItem>
          </Select>
        </FormControl>
        <div style={{ width: "100%" }}>
          {" "}
          <TextField
            fullWidth
            label="SEO Title"
            sx={{ width: "100%", backgroundColor: "white" }}
            value={title}
            onChange={(e) => settitle(e.target.value)}
            id="outlined-size-small"
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          {" "}
          <TextField
            label="Meta Description"
            multiline
            rows={5}
            sx={{ backgroundColor: "white" }}
            fullWidth
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            id="outlined-size-small"
            size="small"
          />
        </div>
        <div style={{ width: "100%" }}>
          {" "}
          <TextField
            label="Meta Tags"
            fullWidth
            sx={{ backgroundColor: "white" }}
            multiline
            value={metaTag}
            onChange={(e) => setmetaTag(e.target.value)}
            rows={5}
            id="outlined-size-small"
            size="small"
          />
        </div>
        <Button fullWidth type="submit" variant="contained">
          {loader ? <CircularProgress color="secondary" /> : "Save"}
        </Button>
      </Box>
    </div>
  );
};

export default SEO;
