import React from "react";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faContactBook,
  faEnvelope,
  faHome,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Drawer from "@mui/material/Drawer";

const DrawerComponent = ({ isDrawerOpen, closeDrawer }) => {
  return (
    <Drawer anchor="left" open={isDrawerOpen} onClose={closeDrawer}>
      <Box sx={{ width: 250, height: "100%" }} role="presentation">
        <ul className="list listInsideDrawer">
          <li onClick={closeDrawer}>
            <FontAwesomeIcon icon={faHome} />
            <a href="/">home</a>
          </li>
          <li onClick={closeDrawer}>
            <FontAwesomeIcon icon={faContactBook} />
            <a href="/#listings">directory</a>
          </li>
          <li onClick={closeDrawer}>
            <FontAwesomeIcon icon={faInfoCircle} />
            <a href="/#aboutus">about us</a>
          </li>
          <li onClick={closeDrawer}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="/#contactus">contact</a>
          </li>
        </ul>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
