import React, { useState, useEffect, useMemo } from "react";
import "./hero.css";
import SearchBox from "./searchBox";

const Hero = ({ images }) => {
  const imagesToRender = useMemo(() => images, []);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <>
      <div className="heroSection">
        <div className="heroContent">
          <h2>
            Welcome to Pondicherry's <br /> first eco-friendly directory
          </h2>
          {/* <h4>live sustainably</h4> */}
          <SearchBox classes="" />
        </div>

        <div className="heroImage">
          <img alt="hero" src={imagesToRender[currentImage]} />
          <SearchBox classes="searchBoxInsideImage" />
        </div>
      </div>
      <div className="contentForSmallDevice">
        <h2>
          Welcome to Pondicherry's <br /> first eco-friendly directory
        </h2>
        {/* <h4>live sustainably</h4> */}
      </div>
    </>
  );
};

export default Hero;
