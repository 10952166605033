import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div id="contactus" className="contact">
      <h1>Contact us</h1>
      <div className="boxWrapper">
        <div className="getInTouchBox">
          <h2>
            You have more sustainable, local, conscious brands, products,
            addresses to suggest ?
          </h2>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLScL-cbhs_g8c5Lt3pg-BrzuLNzmLRtXTKVbzSagh-MwdrrQRg/viewform"
          >
            Connect Here
          </a>
        </div>
        <div className="getInTouchBox">
          <h2>
            You have eco-events to promote ? <br /> An eco-article to share ?
          </h2>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLScK32Aj47GNFx99-s3ceMetrGANUWnHX7H7RzexU6uKzJJ9EQ/viewform"
          >
            Connect here
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
