import React, { useState, useEffect } from "react";
import "./profile.css";
import avatar from "../../assets/images/avatar.png";

import {
  updateProfileInFirestore,
  fetchProfileDataFromFirestore,
  uploadImageToStorage,
} from "../../firebase/functions";
import { useParams } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// ** Third Party Imports
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

// ** MUI Imports
import TextField from "@mui/material/TextField";

// ** React Import
import { forwardRef } from "react";
import {  toast } from "react-toastify";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  alignItems: "flex-start",
  width: "100%",
  "& .MuiInputLabel-root": {
    transform: "none",
    lineHeight: 1.154,
    position: "relative",
    marginBottom: "8px", // Adjusted from theme.spacing(1)
    fontSize: "0.875rem", // Adjusted from theme.typography.body2.fontSize
    color: "rgba(0, 0, 0, 0.87) !important", // Adjusted from theme.palette.text.primary
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    backgroundColor: "transparent !important",
    border: "1px solid rgba(0, 0, 0, 0.2)", // Adjusted from theme.palette.customColors.main
    transition: "border-color 0.2s, box-shadow 0.2s",
    "&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover": {
      borderColor: "rgba(0, 0, 0, 0.28)", // Adjusted from theme.palette.customColors.main
    },
    "&:before, &:after": {
      display: "none",
    },
    "&.MuiInputBase-sizeSmall": {
      borderRadius: "6px",
    },
    "&.Mui-error": {
      borderColor: "#f44336", // Adjusted from theme.palette.error.main
    },
    "&.Mui-focused": {
      boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)", // Adjusted from theme.shadows[2]
      "& .MuiInputBase-input:not(.MuiInputBase-readOnly):not([readonly])::placeholder":
        {
          transform: "translateX(4px)",
        },
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important", // Adjusted from theme.palette.action.selected
    },
    "& .MuiInputAdornment-root": {
      marginTop: "0 !important",
    },
  },
  "& .MuiInputBase-input": {
    color: "rgba(0, 0, 0, 0.87)", // Adjusted from theme.palette.text.secondary
    "&:not(textarea)": {
      padding: "15.5px 13px",
    },
    "&.MuiInputBase-inputSizeSmall": {
      padding: "7.5px 13px",
    },
    "&:not(.MuiInputBase-readOnly):not([readonly])::placeholder": {
      transition: "opacity 0.2s, transform 0.2s",
    },
  },
  "& .MuiFormHelperText-root": {
    lineHeight: 1.154,
    margin: "8px 0 0", // Adjusted from theme.spacing(1)
    color: "rgba(0, 0, 0, 0.54)", // Adjusted from theme.palette.text.secondary
    fontSize: "0.875rem", // Adjusted from theme.typography.body2.fontSize
    "&.Mui-error": {
      color: "#f44336", // Adjusted from theme.palette.error.main
    },
  },
  "& + .react-datepicker__close-icon": {
    top: "11px", // Adjusted from default value
    "&:after": {
      fontSize: "1.6rem !important",
    },
  },
}));

const CustomTextField = forwardRef((props, ref) => {
  // ** Props
  const { size = "small", InputLabelProps, ...rest } = props;

  return (
    <TextFieldStyled
      size={size}
      fullWidth
      inputRef={ref}
      {...rest}
      variant="filled"
      InputLabelProps={{ ...InputLabelProps }}
    />
  );
});

const ImgStyled = styled("img")(({ theme }) => ({
  width: 100,
  height: 100,
  marginRight: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const initialData = {
  fullName: "",
  linkedin: "",
  bio: "",
  profilePicture: "",
};
const Profile = () => {
  const params = useParams();

  // ** State
  const [inputValue, setInputValue] = useState("");
  const [loader, setloader] = useState(false);
  const [imgSrc, setImgSrc] = useState(avatar);
  const [imageFile, setimageFile] = useState(null);
  const [formData, setFormData] = useState(initialData);
  const [dataFetched, setDataFetched] = useState(false);

  const [initialLoader, setinitialLoader] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      const userId = sessionStorage.getItem("userId");
      if (userId && !dataFetched) {
        const profileData = await fetchProfileDataFromFirestore(userId);

        if (profileData !== null) {
          setFormData(profileData);
          setinitialLoader(false);
          setImgSrc(
            profileData.profilePicture ? profileData.profilePicture : avatar
          );
        } else {
          toast.error("Error occured, try again");
          setinitialLoader(false);
        }

        setDataFetched(true);
      }
    };

    if (params.state === "profile") {
      fetchProfileData();
    }
  }, [dataFetched, params.state]); // Add dataFetched to the dependency array

  const handleInputImageChange = (file) => {
    const reader = new FileReader();
    const { files } = file.target;
    if (files && files.length !== 0) {
      reader.onload = () => setImgSrc(reader.result);
      reader.readAsDataURL(files[0]);
      setimageFile(files[0]);
      if (reader.result !== null) {
        setInputValue(reader.result);
      }
    }
  };
  const handleInputImageReset = () => {
    setInputValue("");
    setimageFile(null);
    setImgSrc(formData.profilePicture ? formData.profilePicture : avatar);
  };

  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const isImage = (file) => {
    const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"]; // Add more types if needed
    return allowedImageTypes.includes(file.type);
  };

  const handleSaveChanges = async (e) => {
    setloader(true);
    e.preventDefault();
    // Assuming you have the user ID available in your component
    const userId = sessionStorage.getItem("userId"); // Replace with the actual user ID
    let downloadURL = null;
    if (!userId) {
      setloader(false);
      return;
    }

    if (imageFile !== null) {
      if (!isImage(imageFile)) {
        setloader(false);
        toast.error("Only Image are acceptable as profile picture.");
        return;
      }
      // Upload the image to Firebase Storage
      downloadURL = await uploadImageToStorage(
        imageFile,
        `userImages/profilePicture`
      );

      if (downloadURL !== null) {
        setImgSrc(downloadURL);
        setFormData({ ...formData, profilePicture: downloadURL });
        // Set the download URL or use it as needed in your component
      } else {
        toast.error("Error occured, try again later");
        setloader(false);
      }
    }

    if (!formData.bio && !formData.fullName && !formData.linkedin) {
      setloader(false);
      toast.error("All Fields are empty");
      return;
    }

    // Prepare the data to be saved
    const profileData = {
      fullName: formData.fullName,
      linkedin: formData.linkedin,
      bio: formData.bio,
      profilePicture:
        downloadURL !== null ? downloadURL : formData.profilePicture,
    };
    // Call the Firebase function to update or create the document
    const result = await updateProfileInFirestore(
      userId,
      profileData,
      setloader
    );
    if (result === null) {
      toast.error("Error occured, try again");
    } else {
      toast.success(result);
    }
  };

  return (
    <div className="profile">
      {/* Account Details Card */}
      {initialLoader ? (
        <CircularProgress />
      ) : (
        <Grid item xs={12}>
          <Box>
            <CardHeader title="Profile Details" />
            <form onSubmit={handleSaveChanges}>
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ImgStyled src={imgSrc} alt="Profile Pic" />
                  <div>
                    <ButtonStyled
                      component="label"
                      variant="contained"
                      htmlFor="account-settings-upload-image"
                    >
                      Upload New Photo
                      <input
                        hidden
                        type="file"
                        value={inputValue}
                        accept="image/png, image/jpeg"
                        onChange={handleInputImageChange}
                        id="account-settings-upload-image"
                      />
                    </ButtonStyled>
                    <ResetButtonStyled
                      color="secondary"
                      variant="tonal"
                      onClick={handleInputImageReset}
                    >
                      Reset
                    </ResetButtonStyled>
                    <Typography sx={{ mt: 4, color: "text.disabled" }}>
                      Allowed PNG or JPEG. Max size of 800K.
                    </Typography>
                  </div>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      placeholder="fullName"
                      value={formData.fullName}
                      onChange={(e) =>
                        handleFormChange("fullName", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="linkedin"
                      placeholder="linkedin"
                      value={formData.linkedin}
                      onChange={(e) =>
                        handleFormChange("linkedin", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      rows={10}
                      multiline
                      label="About"
                      value={formData.bio}
                      onChange={(e) => handleFormChange("bio", e.target.value)}
                      placeholder=""
                      id="textarea-outlined-static"
                    />
                    <p>
                      Note: Please add {`<br>`} at the end of line for a line
                      break.
                    </p>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ pt: (theme) => `${theme.spacing(6.5)} !important` }}
                  >
                    <Button variant="contained" type="submit" sx={{ mr: 4 }}>
                      {loader ? <CircularProgress /> : "Save Changes"}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Box>
        </Grid>
      )}
    </div>
  );
};

export default Profile;
